import { Component, OnInit, ViewChild, PipeTransform } from '@angular/core';
import { EngagementDashboardtItem } from '../models/engagement-dashboard-item';
import { EngagementDashboardService } from '../shared/services/engagement-dashboard.service';
import { EngagementTableComponent } from '../engagement-table/engagement-table.component';
import { Spcrud } from 'src/shared/services/spcrud';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EngagementTableService } from '../shared/services/engagement-table.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { AdminService } from 'src/app/services/admin.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { DealwbsService } from 'src/app/services/dealwbs.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { firstValueFrom } from 'rxjs';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
const GRAPH_ENDPOINT_firstpart = 'https://graph.microsoft.com/v1.0/groups/';
const GRAPH_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName&$top=200';

@Component({
  selector: 'app-engagement-dashboard',
  templateUrl: './engagement-dashboard.component.html',
  styleUrls: ['./engagement-dashboard.component.scss']
})
export class EngagementDashboardComponent implements OnInit {

  @ViewChild(EngagementTableComponent, {static: true})
  engagementTableComponent: EngagementTableComponent;
  closingCheckListData:any
  dealWbsData:any
  engagementsList: Array<EngagementDashboardtItem> = [];
  showClosedDeals = false;
  showReportDashhboard = false;
  newDealUrl: string;
  reportDashboardUrl: string;
  xborderDashboardUrl: string;
  adGroupMember:any;
  engagementHealthObjectUrl: string;
  paginationValues: Array<number>;  
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;
  isADGroupUser: boolean | null;
  constructor(
    private engagementDashboardService: EngagementDashboardService,
    private httpClient: HttpClient,
    private authService: MsalService,
    private mainService: MainService,
    private engagementTableService:EngagementTableService,
    private spcrud: Spcrud,
    private spinnerService: SpinnerService,
    private newDealService:NewdealService,
   private adminService: AdminService, private commonHelperService: CommonHelperService,
   private dealWbsService: DealwbsService, private closingCheckListService: ClosingCheckListService,
   public globalHelperService: GlobalHelperService
  ) {}

  ngOnInit() {
    localStorage.setItem("CurrentAccessPage", "EngagementDashboard");
    this.globalHelperService.currentAccessPage="EngagementDashboard";
    this.commonHelperService.state = this.commonHelperService.getSearchState();
    this.spinnerService.hide();
    this.spinnerService.showAutoSaveSpinner();
    this.removeBrowserScroll();  
    this.commonHelperService.getUserGroupMembership().subscribe((res:any) => {
      this.isADGroupUser = res?.isADGroupUser;;
      if(res!=null && res!=undefined)
        {
            if (this.isADGroupUser) {
              localStorage.setItem('isADGroupUser',"true");
              this.getAdGroup();
              }
              else{
              localStorage.setItem("isADGroupUser","false");
              this.spinnerService.hideAutoSaveSpinner();
              this.commonHelperService.showFade();
              this.notifypopupComponent.openPopup("EngagementDashboard","AccessDenied");
            }  
      }
    });  
    
  }  
  isInITSAdminGroup(userGroups: any) {
    let isAdmin_flag = "false";
    if(userGroups!=undefined && userGroups.length > 0)
    {
      userGroups.forEach((u: any) => {
        if (u == "ITS_Administrator") {
          isAdmin_flag = "true";
        }
      });
    }
    return isAdmin_flag;
  }
   addNotifyDetails(event:any){
    if(event!=null){
      document.body.style.overflow="auto";
      window.location.href=AppSettings.DashboardURL;
    }
    }
  removeBrowserScroll(){
    document.body.style.overflow="hidden";
  }

  getAdGroup() {   
        Promise.all([
          this.mainService.getCurrentUserData(),
          this.engagementDashboardService.retrieveEngagements(),
          firstValueFrom(this.dealWbsService.GetDealWbsDetails("",AppSettings.dealWbsSetupFormList)),
          firstValueFrom(this.closingCheckListService.GetClosingCheckListFormDetails("",AppSettings.closingChecklistFormList)),
          firstValueFrom(this.mainService.getAssignedMyDeals(localStorage.getItem("userProfileId")?.toString()))          
        ]).
          then(([currentUser,responseEngagements,dealwbsData,closingCheckListdata,data]) => {
            this.mainService.currentUser = currentUser;
            this.mainService.assignedMyDealIds = this._removeDuplicateMyDeals(data);                          
            this.engagementTableComponent.DealWbsData = dealwbsData;
            this.engagementTableComponent.closingCheckListData = closingCheckListdata;
            // this.mainService.assignedMyDealIds = this.GetAssignedandRemoveDuplicateDummyData(data,currentUser);
              if (this.mainService.currentUser!=null && this.mainService.currentUser!=undefined && this._isDCFAdmin(this.mainService.currentUser.groups) ) {
                // if(false){
                this.showReportDashhboard = true;
                this.engagementsList = responseEngagements;
              }
              else {
                if (this.mainService.currentUser!=null && this.mainService.currentUser!=undefined && this._isClosingCheckListAdmin(this.mainService.currentUser.groups))
                {
                  this.showReportDashhboard = true;
                }
                this.engagementsList = this._getAssignedEngagements(responseEngagements, this.mainService.assignedMyDealIds);
              }
            this.engagementTableComponent.initialEngagementsList = this.engagementsList;
            this.newDealUrl = this.getFormUrl(AppSettings.newDealSetupFormList);
            this.reportDashboardUrl = this.getFormUrl(AppSettings.reportDashboardFormList);
            this.xborderDashboardUrl = this.getFormUrl(AppSettings.xborderDashboardUrl);
            this.engagementHealthObjectUrl = this.getFormUrl(AppSettings.engagementHealthList);
            this.engagementTableComponent.setEngagements(
              this.engagementsList, this.engagementHealthObjectUrl, this.showClosedDeals, this.paginationValues);
              this.spinnerService.hideAutoSaveSpinner();              
          });
  }

  openNewTab(url: string) {
    window.open(url);
  }
  getFormUrl(form: string): string {
    if (form === AppSettings.newDealSetupFormList) {
      return `${AppSettings.newDealBaseUrl}/Pages/${form}.aspx`;
    }
    if (form === AppSettings.reportDashboardFormList) {
      return `${AppSettings.reportDashboardBaseUrl}/Pages/${form}.aspx`;
    }
    if (form === AppSettings.xborderDashboardUrl) {
      return `${AppSettings.xborderDashboardBaseUrl}/Pages/${form}.aspx`;
    }
    return `${this.spcrud.baseUrl}/Pages/${form}.aspx`;
  }

  onSearchResult(result:any) {
    // this.engagementsList = result;
    // this.engagementTableComponent.setEngagements(
    //   this.engagementsList, this.engagementHealthObjectUrl, this.showClosedDeals, this.paginationValues);

    this.engagementTableComponent.onSearchResult(result);

  }

  onShowDealClosedState(result:any) {
    this.showClosedDeals = result;
  }

  //private _isDCFMemberInAdGroup(email:string,groups:Array<any>): boolean {
  //  return !!groups.find(group => group.userPrincipalName === 'sponnada@deloitte.com');
  //}

  //private _isInAdGroup(email: string, group:Array<any>): boolean {
  //  if(group.length!=0)
  //    return group.find(user => user.userPrincipalName ===email);
  //    else
  //    return false;
  //    //return group.find(user => user.Email === email);
  //}
  toggleClosedDeals() {
    this.showClosedDeals = !this.showClosedDeals;
    const searchTerm = this.engagementTableComponent.searchTerm.trim();
    if(searchTerm!="" && searchTerm!="ALL"){
      var filteredList = this.engagementsList.filter((item) => {
        // Apply filter conditions on each column
        return (
          item.engagementName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      this.engagementTableComponent.setEngagements(
        filteredList, this.engagementHealthObjectUrl, this.showClosedDeals, this.paginationValues);  
    }
    else
      this.engagementTableComponent.setEngagements(
      this.engagementsList, this.engagementHealthObjectUrl, this.showClosedDeals, this.paginationValues);
      this.engagementTableComponent.selectedPageSize = this.engagementTableService.state.pageSize = AppSettings.EngagementDefaultPageSize;
  }

  clearFilters() {
    this.engagementTableComponent.clearAllFilters();
    //clear the text in search box
    this.commonHelperService.searchTerm = "";
  }
  private _removeDuplicateMyDeals(data:any) : number[]{
    let ids:any = []
    data?.map((x:any) => {
      if (!ids.includes(x.FormID))
        ids.push(x.FormID)
    });
    return ids;
  }

  private _isDCFAdmin(groups: Array<string>): boolean {
    const myDealAdminGroup = AppSettings.myDealAdminsGroup;
    return !!groups.find(group => group === myDealAdminGroup);
  }

  private _isClosingCheckListAdmin(groups: Array<string>): boolean {
    const closingCheckListGroup = AppSettings.closingchecklistGroup;
    return !!groups.find(group => group === closingCheckListGroup);
  }


  private _isNewDealAdminOrAnalyst(groups:Array<string>): boolean {
      return !!groups.find(group=> group === AppSettings.newDealAdminsGroup || group === AppSettings.associateAnalystGroup);
  }
  private _getAssignedEngagements(responseEngagements: any, assignedMyDealIds: number[]): EngagementDashboardtItem[] {
    return responseEngagements.filter((eng:any) => assignedMyDealIds.includes(eng.myDealId));
  }


   addNewDeal(){
    this.openNewTab(environment.redirectUrl+"newdeal");
  }

  openCrossBorder(){
    this.openNewTab(environment.redirectUrl+"Dashboard");
  }

  
}


 

